<template>
  <NavBar v-if="$route.meta.isNavVisible" />
  <AlertsContainer v-if="hasAlerts" />
  <router-view />
  <FFooter v-if="$route.meta.isNavVisible" />
</template>

<script>
import NavBar from '@/components/NavBar';
import FFooter from '@/components/FFooter';
import AlertsContainer from '@/components/Alert/AlertsContainer.vue';
import { mapGetters } from 'vuex';
import "@/assets/style.css";

export default {
  components: {
    NavBar,
    FFooter,
    AlertsContainer
  },
  data() {
    return {
      isAdminPage: false // Set to true when on admin page
    };
  },
  computed: {
    ...mapGetters(['hasAlerts'])
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;

}

a {
  text-decoration: none;
}
</style>
