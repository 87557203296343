<template>
  <div :class="$style.cont">
    <div :class="$style.controller">
      <button @click="changeLange('arm')">ARM</button>
      <button @click="changeLange('eng')">ENG</button>
      <button @click="changeLange('rus')">RUS</button>
      <button @click="saveData">Save</button>
    </div>
    <header>
      <h1>{{ data.headerTitle[currLange] }}</h1>
    </header>
    <main>
      <button :class="$style.addBut" @click="() => this.isAdd = true">Add Element</button>
      <div v-if="isAdd" :class="$style.addCont">
        <label>
          Title
          <input type="text" v-model="addTitle">
        </label>
        <label>
          Text
          <input type="text" v-model="addText">
        </label>
        <button :class="$style.subBut" @click="addSection">OK</button>
      </div>
      <AdminServiceSection v-for="(item, index) in data.sections" :title="item.title" :lists="item.lists"
        :lang="currLange" :index="index" @removeEl="removeEL" :key="`servicePage${index}`" />
    </main>
  </div>
</template>
<script>
import axios from 'axios';
import AdminServiceSection from "@/components/Admin/AdminServiceSection.vue"
import { mapActions } from 'vuex';

export default {
  components: {
    AdminServiceSection
  },
  data() {
    return {
      currLange: "arm",
      isAdd: false,
      addTitle: "",
      addText: "",
      addSubTitle: "",
      data: {
        headerTitle: {},
        sections: []
      },

    }
  },
  mounted() {
    this.fetchData();

  },
  methods: {
    ...mapActions(["triggerAlert"]),

    async fetchData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/servicesData`);
        this.data = response.data.pageData;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async saveData() {
      try {
        let token = sessionStorage.getItem("jknkjnk");
        await axios.post(`${process.env.VUE_APP_API_URL}/api/saveServicesData`, this.data, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.triggerAlert({
          type: "success",
          message: "Data saved successfully!",
        });
      } catch (error) {
        this.triggerAlert({
          type: "warning",
          message: "Something is wrong. Please try agian!",
        });
      }
    },
    changeLange(lang) {
      this.currLange = lang
    },
    addSection() {
      this.data.sections.push({
        title: { [this.currLange]: this.addTitle },
        lists: [
          { [this.currLange]: this.addText }
        ],
      })
      this.addTitle = "";
      this.addText = "";
      this.isAdd = false
    },
    removeEL(index) {
      this.data.sections = this.data.sections.filter((el, ind) => {
        return ind !== index
      })
    }
  }
}

</script>

<style module>
.cont {
  width: 100%;
  margin-top: 4vh;
}

.cont>header {
  width: 100%;
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  background: var(--yellow);
  color: #fff;
}

.cont>header>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.controller {
  position: fixed;
  right: 30px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.controller>button {
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
  background-color: rgb(66, 66, 66);
  color: #fff;
  transition: .3s
}

.controller>button:hover {
  background-color: #fff;
  color: #000;
}

.addBut {
  width: fit-content;
  background-color: green;
  color: #fff;
  border: 1px solid green;
  outline: none;
  padding: 6px 20px;
  border-radius: 7px;
  font-size: 18px;
  cursor: pointer;
}

.addCont {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  background-color: #fff;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #000;
}

.addCont>label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subBut {
  width: fit-content;
  color: green;
  border: 1px solid green;
  background-color: transparent;
  outline: none;
  padding: 4px 15px;
  border-radius: 7px;
  font-size: 16px;
  cursor: pointer;
}


.cont textarea {
  width: 100%;
  min-height: 80px;
  height: fit-content;
  padding: 5px;
  resize: vertical;
}


.cont>main {
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.cont>main>div {
  display: flex;
  flex-direction: column;
  gap: 4vh;
}

.cont h4 {
  font-size: 30px;
}

.cont ul {
  font-size: 21px;
  line-height: 31px;
  padding-left: 5px;
}

.cont li {
  margin-left: 25px;
  padding: 4px 0;
  font-size: 16px;

}
</style>