<template>
    <div :class="$style.cont">
        <h4>{{ ctitleText[lang] }}</h4>
        <p>{{ ctext[lang] }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: Object,
        text: Object,
    },
    computed: {
        ctitleText() {
            return this.title
        },
        ctext() {
            return this.text
        },
        lang() {
            // Access lang meta field from $route
            return this.$route.meta.lang || 'arm';
        }
    },
}
</script>

<style module>

.cont {
    display: flex;
    flex-direction: column;
    gap: 4vh;
}

.cont h4 {
    font-size: 24px;
    font-weight: 500;
}

@media screen and (max-width:900px) {
  .cont {
    gap: 2vh;
  }
}

@media screen and (max-width:500px) {
    .cont h4 {
        font-size: 20px;
    }

    .cont p {
        font-size: 16px;
        line-height: 31px;
        padding-left: 5px;
    }

}
</style>