<template>
  <div :class="$style.cont">
    <h1>Admin Settings</h1>
    <div :class="$style.formcont">
      <form :class="$style.credForm" @submit.prevent="handleChangePassword">
        <h2>Credantials</h2>
        <div>
          <label for="">
            Username
            <input type="text" v-model="username">
          </label>
          <label for="">
            Password
            <input type="text" v-model="newPassword">
          </label>
          <label for="">
            Old Password
            <input type="text" v-model="oldPassword">
          </label>
        </div>
        <button>ok</button>
      </form>
      <form @submit.prevent="updateLanguages">
        <h2>Languages</h2>
        <div>
          <label>
            <input type="checkbox" v-model="localLanguages" value="arm" />
            Armenian
          </label>
          <label>
            <input type="checkbox" v-model="localLanguages" value="eng" />
            English
          </label>
          <label>
            <input type="checkbox" v-model="localLanguages" value="rus" />
            Russian
          </label>
          <!-- Add other languages as needed -->
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      visibleImages: [],
      username: "",
      newPassword: "",
      oldPassword: "",
      localLanguages: []
    }
  },
  mounted() {
    // Initialize localLanguages with the current allowedLanguages from Vuex
    this.localLanguages = [...this.allowedLanguages];
  },
  computed: {
    ...mapGetters(["allowedLanguages"])
  },
  methods: {
    ...mapActions(["triggerAlert"]),
    ...mapMutations(['setAllowedLanguages']),
    async handleChangePassword() {
      try {
        let token = sessionStorage.getItem("jknkjnk");
        const reqdata = {
          username: this.username,
          newPassword: this.newPassword,
          oldPassword: this.oldPassword,
        }
        await axios.post(`${process.env.VUE_APP_API_URL}/api/authentication/changCredaintials`, reqdata, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.password = "";
        this.oldPassword = "";
        this.oldPassword = ""
        this.triggerAlert({
          type: "success",
          message: "Your creditions saved successfully!",
        });
      } catch (error) {
        this.password = "";
        this.oldPassword = "";
        this.oldPassword = ""
        this.triggerAlert({
          type: "warning",
          message: "Something is wrong. Please try agian!",
        });
      }
    },
    async updateLanguages() {
      try {
        const token = sessionStorage.getItem("jknkjnk");
        // Make an API call to update the allowed languages on the server
        await axios.post(`${process.env.VUE_APP_API_URL}/api/settings/changeLanguages`, {
          allowedLanguages: this.localLanguages,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        this.setAllowedLanguages(this.allowedLanguages);
        this.triggerAlert({
          type: "success",
          message: "Languages changed successfully!",
        });
      } catch (error) {
        this.triggerAlert({
          type: "warning",
          message: "Something is wrong. Please try agian!",
        });
      }
    },
  }
}
</script>

<style module>
.cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4vh;
  margin-top: 4vh;
  padding: 0 5%;
}

.cont h1 {
  text-align: center;
  font-size: 25px;
}

.formcont {
  display: flex;
}

.formcont>form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  padding: 15px;
}

.formcont>form>div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0;
}

.formcont>.credForm label {
  display: flex;
  flex-direction: column;
}

.formcont>.credForm input {
  width: 300px;
}

.formcont>form label {
  display: flex;
  gap: 10px;
}

.formcont button{
  width: fit-content;
  background-color: green;
  color: #fff;
  border: 1px solid green;
  outline: none;
  padding: 3px 30px;
  border-radius: 7px;
  font-size: 18px;
  cursor: pointer;
}
</style>