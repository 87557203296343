<template>
    <div :class="$style.cont">
        <button :class="$style.remove" @click="$emit('removeEl', index)">Remove</button>
        <input v-model="ctitleText[lang]" />


        <button :class="$style.addButSection" @click="() => this.isAddSection = true">Add New Section</button>
        <div v-if="isAddSection" :class="$style.addCont">
            <label>
                SubTitle
                <input type="text" v-model="addSectionTitle">
            </label>
            <label>
                Text
                <input type="text" v-model="addSectionText">
            </label>
            <button :class="$style.subBut" @click="addSection">OK</button>
        </div>

        <ul v-for="(list, listKey) in clists" :key="listKey">

            <template v-if="list.subTitle">
                <textarea type="text" v-model="list.subTitle[lang]"></textarea>
            </template>
            <button :class="$style.addBut" @click="() => this.isAdd = true">Add List Element</button>
            <div v-if="isAdd" :class="$style.addCont">
                <label>
                    Text
                    <input type="text" v-model="addText">
                </label>
                <button :class="$style.subBut" @click="addList(listKey)">OK</button>
            </div>
            <li v-for="(item, index) in list.listElems" :key="`aboutPageLi${index}`">
                <textarea type="text" v-model="item[lang]"></textarea>
                <button :class="$style.removeList" @click="removeListEl(listKey, index)">-</button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        title: Object,
        lists: Array,
        lang: String,
        index: Number
    },
    computed: {
        ctitleText() {
            return this.title || {}; // Ensure title is defined or default to an empty object
        },
        clists() {
            return this.lists || []; // Ensure lists is defined or default to an empty array
        }
    },
    data() {
        return {
            isAdd: false,
            addText: "",
            addSectionText: "",
            addSectionTitle: "",
            isAddSection: false,
        }
    },
    methods: {
        addList(index) {
            this.clists[index].listElems.push(
                {
                    [this.lang]: this.addText
                });
            console.log(this.clists[index].listElems);
            this.addText = "",
                this.isAdd = false
        },
        removeListEl(listIndex, index) {
            this.clists[listIndex].listElems.splice(index, 1)
        },
        addSection() {
            this.clists.push(
                {
                    subTitle: {
                        [this.lang]: this.addSectionText
                    },
                    listElems: [
                        {
                            [this.lang]: this.addSectionTitle
                        }
                    ]
                });
            this.addSectionText = "",
            this.addSectionTitle = "",
                this.isAddSection = false
        }
    }
};
</script>

<style module>
.cont {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    position: relative;
    border: 1px solid rgb(233, 233, 233);
}

.cont input {
    font-size: 30px;
}

.cont textarea {
    width: 100%;
    min-height: 80px;
    height: auto;
    padding: 5px;
    resize: vertical;
}

.cont ul {
    font-size: 21px;
    line-height: 31px;
    padding-left: 5px;
}

.cont li {
    margin-left: 25px;
    padding: 4px 0;
    font-size: 16px;
    position: relative;

}

.remove {
    position: absolute;
    top: -13px;
    right: 10px;
    width: fit-content;
    border: 1px solid red;
    background-color: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 7px;
    cursor: pointer;
}

.removeList {
    position: absolute;
    top: -10px;
    right: 5px;
    width: fit-content;
    border: 1px solid red;
    background-color: red;
    color: #fff;
    padding: 2px 10px;
    border-radius: 7px;
    cursor: pointer;
}

.addBut {
    width: fit-content;
    background-color: green;
    color: #fff;
    border: 1px solid green;
    outline: none;
    padding: 4px 10px;
    margin: 5px 0;
    border-radius: 7px;
    font-size: 14px;
    cursor: pointer;
}

.addButSection {
    position: absolute;
    top: -13px;
    right: 90px;
    width: fit-content;
    background-color: green;
    color: #fff;
    border: 1px solid green;
    outline: none;
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 14px;
    cursor: pointer;
}

.addCont {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    background-color: #fff;
    padding: 10px;
    margin: 10px 0;
    border-radius: 7px;
    font-size: 14px;
    border: 1px solid #000;
}

.addCont>label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.subBut {
    width: fit-content;
    color: green;
    border: 1px solid green;
    background-color: transparent;
    outline: none;
    padding: 4px 15px;
    border-radius: 7px;
    font-size: 16px;
    cursor: pointer;
}
</style>
