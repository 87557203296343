<template>
    <div :class="$style.cont">
        <h4>{{ ctitleText[lang] }}</h4>
        <ul v-for="(list, listKey) in clists" :key="listKey">
            <p v-if="list.subTitle">{{ list.subTitle[lang] }} </p>
            <li v-for="(item, index) in list.listElems" :key="`aaboutPageLi${index}`">{{ item[lang] }} </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        title: Object,
        lists: Array,
    },
    computed: {
        ctitleText() {
            return this.title || {}; // Ensure title is defined or default to an empty object
        },
        clists() {
            return this.lists || []; // Ensure lists is defined or default to an empty array
        },
        lang() {
            // Access lang meta field from $route
            return this.$route.meta.lang || 'arm';
        }
    }
};
</script>

<style module>
.cont {
    display: flex;
    flex-direction: column;
    gap: 4vh;
}

.cont h4 {
    font-size: 30px;
}

.cont ul {
    font-size: 21px;
    line-height: 31px;
    padding-left: 5px;
}

.cont li {
    margin-left: 25px;
    padding: 4px 0;
    font-size: 16px;

}

@media screen and (max-width:500px) {
    .cont>header {
        background: linear-gradient(var(--yellow) 0% 25%, #fff 25%);
    }

    .cont h4 {
        font-size: 24px;
    }

    .cont ul {
        font-size: 18px;
        line-height: 31px;
        padding-left: 5px;
    }

    .cont li {
        margin-left: 25px;
        padding: 4px 0;
        font-size: 16px;

    }
}
</style>
