<template>
    <div :class="$style.cont">
        <h4>{{ ctitleText[lang] }}</h4>
        <ul>
            <li v-for="(item, index) in clists" :key="`sservicePageLi${index}`">{{ item[lang] }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        title: Object,
        lists: Array,
    },
    computed: {
        ctitleText() {
            return this.title || {};
        },
        clists() {
            return this.lists || [];
        },
        lang() {
            // Access lang meta field from $route
            return this.$route.meta.lang || 'arm';
        }
    },
    data() {
        return {
            isAdd: false,
            addText: "",
        }
    },
    methods: {
        addList() {
            this.clists.push(
                {
                    [this.lang]: this.addText
                });
            this.addText = "",
                this.isAdd = false
        },
        removeListEl(index) {
            this.clists.splice(index, 1)
        },
    },
};
</script>

<style module>
.cont {
    display: flex;
    flex-direction: column;
    gap: 4vh;
}

.cont h4 {
    font-size: 30px;
}

.cont ul {
    font-size: 21px;
    line-height: 31px;
    padding-left: 5px;
}

.cont li {
    margin-left: 25px;
    padding: 4px 0;
    font-size: 16px;

}

@media screen and (max-width:900px) {
    .cont {
        gap: 2vh;
    }
}

@media screen and (max-width:500px) {
    .cont>header {
        background: linear-gradient(var(--yellow) 0% 25%, #fff 25%);
    }

    .cont h4 {
        font-size: 20px;
    }

    .cont ul {
        font-size: 18px;
        line-height: 31px;
        padding-left: 5px;
    }

    .cont li {
        margin-left: 25px;
        padding: 4px 0;
        font-size: 16px;

    }
}
</style>
