<template>
    <div :class="$style.cont">
        <div :class="$style.nav">
            <router-link :to="{ name: 'admin-home' }">Home</router-link>
            <router-link :to="{ name: 'admin-about' }">About</router-link>
            <router-link :to="{ name: 'admin-advice' }">Advice</router-link>
            <router-link :to="{ name: 'admin-service' }">Service</router-link>
            <router-link :to="{ name: 'admin-setting'}">Setting</router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
export default {

}
</script>

<style module>
.cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #000;
}
.nav{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5%;
    padding: 20px 0;
}

.nav button{
    width: fit-content;
    margin-left: 10%;
    background-color: green;
    color: #fff;
    border: 1px solid green;
    outline: none;
    padding: 5px 15px;
    border-radius: 7px;
    cursor: pointer;
}

.nav button:hover{
    box-shadow: 0 0 15px 1px rgb(137, 177, 137);
}
</style>
