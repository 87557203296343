<template>
    <div :class="[$style.cont, typeStyle]">
        <img :src="icon" alt="alert-icon">
        <div :class="$style.main">
            <h4>{{ title }}</h4>
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            icon: ''
        };
    },
    computed: {
        title() {
            const titles = {
                hint: "Hint Message",
                warning: "Warning Message",
                success: "Success Message"
            };
            return titles[this.type];
        },
        typeStyle() {
            const styles = {
                hint: this.$style.hint,
                warning: this.$style.warning,
                success: this.$style.success
            };
            return styles[this.type];
        }
    },
    async created() {
        this.icon = await this.getIcon(this.type);
    },
    methods: {
        async getIcon(type) {
            const icons = {
                hint: () => import('@/assets/icons/hint_message.svg'),
                warning: () => import('@/assets/icons/warning_message.svg'),
                success: () => import('@/assets/icons/success_message.svg')
            };
            return icons[type]().then(module => module.default);
        }
    }
};
</script>

<style module>
.cont {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 8px;
    border-radius: 8px;
    animation: MoveDown 6s ease-in-out 1 forwards;
}

.cont>img {
    width: 30px;
    object-fit: contain;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
}

.main>h4 {
    font-weight: bold;
}

.hint {
    background-color: #E3F2FD;
    border: 1px solid #3b6d96;
}

.warning {
    background-color: #FFF3E0;
    border: 1px solid #966d2e;
}

.success {
    background-color: #E8F5E9;
    border: 1px solid #256228;
}

@keyframes MoveDown {
    0% {
        opacity: 0;
    }

    30% {
        transform: translateY(50px);
        opacity: 1;
    }

    90% {
        transform: translateY(50px);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(30px);
    }
}
</style>