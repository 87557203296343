import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import AdvicePage from "@/pages/AdvicePage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import ServicesPage from "@/pages/ServicesPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import AdminPage from "@/pages/Admin/AdminPage.vue";
import AdminHomePage from "@/pages/Admin/AdminHomePage.vue";
import AdminAboutPage from "@/pages/Admin/AdminAboutPage.vue";
import AdminAdvicePage from "@/pages/Admin/AdminAdvicePage.vue";
import AdminServicePage from "@/pages/Admin/AdminServicePage.vue";
import AdminSettingPage from "@/pages/Admin/AdminSettingPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import store from '@/store/index'; 

const createDynamicRoutes = (allowedLanguages) => {
  const dynamicRoutes = [
    {
      path: "/",
      name: "home",
      alias: allowedLanguages.map((lang) => `/${lang}`),
      component: HomePage,
      meta: { isNavVisible: true, isAdmin: false },
    },
    {
      path: "/about",
      name: "about",
      alias: allowedLanguages.map((lang) => `/${lang}/about`),
      component: AboutPage,
      meta: { isNavVisible: true, isAdmin: false },
    },
    {
      path: "/advice",
      name: "advice",
      alias: allowedLanguages.map((lang) => `/${lang}/advice`),
      component: AdvicePage,
      meta: { isNavVisible: true, isAdmin: false },
    },
    {
      path: "/service",
      name: "service",
      alias: allowedLanguages.map((lang) => `/${lang}/service`),
      component: ServicesPage,
      meta: { isNavVisible: true, isAdmin: false },
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
      meta: { isNavVisible: false, isAdmin: false },
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminPage,
      redirect: "/admin/home",
      children: [
        {
          path: "home",
          name: "admin-home",
          component: AdminHomePage,
          meta: { isNavVisible: false, isAdmin: true },
        },
        {
          path: "about",
          name: "admin-about",
          component: AdminAboutPage,
          meta: { isNavVisible: false, isAdmin: true },
        },
        {
          path: "advice",
          name: "admin-advice",
          component: AdminAdvicePage,
          meta: { isNavVisible: false, isAdmin: true },
        },
        {
          path: "service",
          name: "admin-service",
          component: AdminServicePage,
          meta: { isNavVisible: false, isAdmin: true },
        },
        {
          path: "setting",
          name: "admin-setting",
          component: AdminSettingPage,
          meta: { isNavVisible: false, isAdmin: true },
        },
      ],
      meta: { isNavVisible: false, isAdmin: true },
    },
    {
      path: "/:pathMatch(.*)*", // Catch-all route for 404 page
      name: "not-found",
      component: NotFoundPage,
      meta: { isNavVisible: false, isAdmin: false },
    },
  ];

  return dynamicRoutes;
};

const initializeRouter = async () => {
  await store.dispatch('fetchAllowedLanguages');
  const allowedLanguages = store.getters.allowedLanguages;
  const routes = createDynamicRoutes(allowedLanguages);

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  router.beforeEach((to, from, next) => {
    const pathLang = to.path.split("/")[1];
    to.meta.lang = allowedLanguages.includes(pathLang) ? pathLang : "arm";

    if (to.matched.some((record) => record.meta.isAdmin)) {
      const isAuthenticated = sessionStorage.getItem("jknkjnk");
      if (!isAuthenticated) {
        next({ name: "home" });
      } else {
        next();
      }
    } else {
      next();
    }
  });

  return router;
};

export default initializeRouter;
