<template>
  <div :class="$style.cont">
    <header>
      <h1>{{data.headerTitle[lang]}}</h1>
    </header>
    <main>
      <ServicesSection v-for="(item, index) in data.sections" :title="item.title" :lists="item.lists"
        :key="`sservicePage${index}`" />
    </main>
  </div>
</template>
<script>import axios from 'axios';
import ServicesSection from "@/components/ServicesSection.vue"

export default {
  components: {
    ServicesSection
  },
  data() {
    return {
      data: {
        headerTitle: {},
        sections: []
      },

    }
  },
  computed: {
    lang() {
      // Access lang meta field from $route
      return this.$route.meta.lang || 'arm';
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/servicesData`);
        this.data = response.data.pageData;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  }
}
</script>

<style module>
.cont {
  width: 100%;
  margin-top: 10vh;
}

.cont>header {
  width: 100%;
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  background: var(--yellow);
  color: #fff;
}

.cont>header>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.cont>main {
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}



@media screen and (max-width:1200px){
  .cont>header {
    height: auto;
    padding: 4vh 5% 3vh;
  }

  .cont>main {
    padding: 3vh 5% 4vh;

  }
}


@media screen and (max-width:900px) {
  .cont>header {
    height: auto;
    padding: 4vh 5% 3vh;
    gap: 2vh;
  }

  .cont>header>h1 {
    font-size: 20px
  }

  ;

  .cont>header>div {
    flex-direction: column;
    gap: 2vh;
  }

  .cont>header>div>p {
    width: 100% !important;
    font-size: 21px;
    line-height: 31px;
  }

  .cont>main {
    padding: 3vh 5% 4vh;
  }

}
</style>
