<template>
    <div :class="$style.cont">

        <div :class="$style.title">
            <div v-if="imgSrc">
                <img :src="require(`@/assets/images/${this.imgSrc}.svg`)" :alt="imgSrc">
            </div>
            <textarea v-model="ctitleText[lang]"></textarea>
        </div>
        <div>
            <textarea v-model="ctext[lang]"></textarea>
            <router-link to="/about">{{ link[lang] }} ></router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imgSrc: String,
        titleText: Object,
        text: Object,
        lang: String
    },
    computed: {
        ctitleText() {
            return this.titleText
        },
        ctext() {
            return this.text
        }
    },
    data() {
        return {
            link: {
                arm: "Իմանալ Ավելին",
                eng: "Know more",
                rus: "Узнать больше"
            }
        }
    }
}
</script>

<style module>
.cont {
    /* width: 30%; */
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--green);
    border-radius: 24px;
    gap: 3vh;
}

.title {
    display: flex;
    align-items: center;
    gap: 10px;

}
.title > textarea{
    font-size: 22px;
}

.cont>div:last-child {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.cont p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.cont a {
    color: var(--green);
}
</style>