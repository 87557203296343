<template>
    <div :class="$style.cont">
        <AlertsContainerItems v-for="(alert, index) in alerts" :key="index" :type="alert.type" >{{ alert.message }}</AlertsContainerItems>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AlertsContainerItems from './AlertsContainerItems.vue';

export default {
    components: {
        AlertsContainerItems
    },
    computed: {
        ...mapState({
            alerts: state => state.alerts
        })
    }
};
</script>

<style module>
.cont{
    width: 760px;
    position: fixed;
    left: calc(50% - 380px);
    z-index: 99999999;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
}
</style>