<template>
  <div :class="$style.cont">
    <div :class="[$style.dropDown, { [$style.activeDrop]: isDropDownOpen, [$style.smallScreen]: size === 'small' }]" @mouseenter="showDropdown"
      @mouseleave="hideDropdown">
      <p>{{ lang }}</p>
      <ul :class="$style.lists" :style="{ display: isDropDownOpen ? 'flex' : 'none'}">
        <li @click="replaceLanguage(item)" v-for="item in langs" :key="`lang${item}`"
          :class="{ [$style.activeListEl]: item.toUpperCase() === lang}">{{
            item.toUpperCase() }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    langs: Array,
    size: String
  },
  data() {
    return {
      isDropDownOpen: false
    }
  },
  methods: {
    replaceLanguage(lang) {
      const path = this.$route.fullPath;

      // Check if the path contains any language prefix from langs array
      const langPrefixRegex = new RegExp(`/(?:${this.langs.join('|')})/`);

      if (langPrefixRegex.test(path)) {
        // If the path contains any language prefix, replace it with the new language
        const newPath = path.replace(langPrefixRegex, `/${lang}/`);
        // this.$router.push(newPath);
        window.location.href = newPath;
      } else {
        // If the path does not contain any language prefix, add the new language prefix at the start
        const newPath = `/${lang}${path}`;
        window.location.href = newPath;
      }
    },
    showDropdown() {
      this.isDropDownOpen = true;
    },
    hideDropdown() {
      this.isDropDownOpen = false;
    }
  },
  computed: {
    lang() {
      // Check if this.$route.meta exists and has the lang property
      if (this.$route.meta && this.$route.meta.lang) {
        return this.$route.meta.lang.toUpperCase();
      } else {
        // Fallback to a default language if meta or lang is undefined
        return 'ARM';
      }
    }
  },
}
</script>

<style module>
.cont {
  display: flex;
  gap: 10px;
  color: var(--green);
  font-size: 16px;
}

.cont .smallScreen{
  width: 50px !important;
}

.dropDown {
  width: 4.4vw;
  position: relative;
  border: 1px solid var(--green);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeDrop {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid transparent;
}

.cont .smallScreen > p{
  padding: 2px 10px;
}

.dropDown>p {
  width: 100;
  padding: 0 10px;
  cursor: pointer;
}

.lists {
  width: calc(100% + 1.5px);
  position: absolute;
  top: 100%;
  left: -1px;
  flex-direction: column;
  list-style-type: none;
  border: 1px solid var(--green);
  border-top: none;
  border-radius: 0 0 8px 8px;
  overflow: hidden;

}
.cont .smallScreen li{
  padding: 5px 7px;
}

.lists li {
  width: 100%;
  padding: 7px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeListEl {
  background-color: var(--green) !important;
  color: #fff !important;
}

.lists li:not(.activeListEl):hover {
  background-color: #9daea8
}


</style>