<template>
    <footer>
        <div :class="$style.top">
            <div>
                <img src="@/assets/images/location.svg" alt="">
                <span>ՀՀ, ք. Երևան 0002, Լեո 1</span>
            </div>
            <div>
                <img src="@/assets/images/tell.svg" alt="">
                <a href="tel:+37411770707">+374 11 77 07 07</a>
            </div>
            <div>
                <img src="@/assets/images/mail.svg" alt="">
                <a href="mailto:info@nrm.am">info@nrm.am</a>
            </div>
        </div>
        <div :class="$style.bottom">
            © 2023 NATURAL RESOURCE MANAGEMENT
        </div>
    </footer>
</template>

<script>

export default {
}
</script>

<style module>
footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 0 10%;
    background-color: var(--green);
    font-size: 15px;

}

footer a,
footer span {
    color: #fff;
}

.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7vh 0 5vh;
    border-bottom: .5px solid #B3C6BE;
}

.top>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.top>div>img {
    width: 16px;
}


.bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5vh 0 7vh;
    color: #fff;
}

@media screen and (max-width:1200px) {
    footer {
        padding: 0 5%;
        font-size: 12px;
    }
}

@media screen and (max-width:700px) {

    footer {
        padding: 0 5%;
        font-size: 11px;
    }

    .top {
        padding: 3vh 0;
    }

    .bottom {
        padding: 3vh 0;
    }

    .top>div {
        gap: 5px;
    }

    .top>div>img {
        width: 9px;
    }

}

@media screen and (max-width:500px) {
    footer {
        padding: 4vh 5%;
        font-size: 12px;
        flex-direction: row;
    }

    .top>div>img {
        width: 13px;
    }

    .top {
        flex-direction: column;
        gap: 15px;
        border-right: .5px solid #B3C6BE;
        border-bottom: none;
        padding: 0 5% 0 0;
    }

    .bottom {
        padding: 0;
        padding-left: 5%;
    }
}

</style>
