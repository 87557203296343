import { createStore } from "vuex";
import { getAllowedLanguages } from "@/services/getLanguages";

export default createStore({
  state() {
    return {
      allowedLanguages: [],
      alerts: [],
      clearTime: 0,
    };
  },
  getters: {
    hasAlerts: (state) => state.alerts.length > 0,
    allowedLanguages(state) {
      return state.allowedLanguages;
    },
  },
  mutations: {
    setAllowedLanguages(state, languages) {
      state.allowedLanguages = languages;
    },
    addAlert(state, alert) {
      state.alerts.push(alert);
    },
    clearAlert(state) {
      state.alerts = [];
    },
  },
  actions: {
    async fetchAllowedLanguages({ commit }) {
      const languages = await getAllowedLanguages();
      commit("setAllowedLanguages", languages);
      return languages;
    },
    triggerAlert({ commit, state }, alert) {
      commit("addAlert", alert);
      state.clearTime++;
      setTimeout(() => {
        state.clearTime--;
        if (!state.clearTime) {
          commit("clearAlert");
        }
      }, 6000);
    },
  },
});
