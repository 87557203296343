<template>
  <div :class="$style.cont">
    <header>
      <h1>{{ data.headerTitle[lang] }}</h1>
      <img :src="imageDataURI" alt="">
    </header>
    <main>
      <AdviceSection v-for="(item, index) in data.sections" :title="item.title" :text="item.text" :key="`aadvicePage${index}`" />

    </main>
  </div>
</template>
<script>
import axios from 'axios';
import AdviceSection from "@/components/AdviceSection"

export default {
  components: {
    AdviceSection
  },
  data() {
    return {
      imageDataURI: "",
      data: {
        headerTitle: {},
        sections: []
      }
    }
  },
  computed: {
    lang() {
      // Access lang meta field from $route
      return this.$route.meta.lang || 'arm';
    }
  },
  mounted() {
    this.fetchData();

  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/adviceData`);

        this.data = response.data.pageData;
        this.imageDataURI = response.data.imageDataURL
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  }
}
</script>

<style module>
.cont {
  width: 100%;
  margin-top: 10vh;
}

.cont>header {
  width: 100%;
  /* height: 75vh; */
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  background: linear-gradient(var(--green) 0% 50%, #fff 50%);
  color: #fff;
}

.cont>header>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.cont>main {
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}


@media screen and (max-width:1200px){
  .cont>header {
    height: auto;
    padding: 4vh 5% 3vh;
  }

  .cont>main {
    padding: 3vh 5% 4vh;

  }
}


@media screen and (max-width:900px) {
  .cont>header {
    height: 80vh;
  }

  .cont>header>div>p {
    font-size: 17px;
  }

  .cont>header>div>p:last-child {
    font-size: 16px;
  }
}



@media screen and (max-width:900px) {

  .cont>header {
    height: auto;
    padding: 4vh 5% 3vh;
    gap: 2vh;
  }

  .cont>header>h1 {
    font-size: 20px
  }

  ;

  .cont>header>div {
    flex-direction: column;
    gap: 2vh;
  }

  .cont>header>div>p {
    width: 100% !important;
    font-size: 21px;
    line-height: 31px;
  }

  .cont>main {
    padding: 3vh 5% 4vh;

  }

}

@media screen and (max-width:600px) {
  .cont>header {
    background: linear-gradient(var(--green) 0% 60%, #fff 60%);
  }
}

@media screen and (max-width:500px) {
  .cont>header {
    background: linear-gradient(var(--green) 0% 65%, #fff 65%);
  }
  .cont h1{
    font-size: 28px;
  }
}
</style>
