<template>
  <div :class="$style.cont">
    <header :style="{
      'background-image': isMobileImageVisible ? 'url(' + this.mobileImageDataURL + ')' : 'url(' + this.imageDataURL + ')',
    }">
      <div :class="$style.header_text">
        <h1>
          NATURAL RESOURCE <br>
          MANAGEMENT
        </h1>
        <ContactButton />
      </div>
    </header>
    <div :class="$style.greenLine">
      <h3>{{ data.greenLineText1[lang] }}</h3>
      <p>{{ data.greenLineText2[lang] }}</p>
    </div>
    <div :class="$style.homeAbout">
      <h2>{{ data.homeAboutTitle[lang] }}</h2>
      <div :class="$style.homeAbout_cont">
        <HomeAbout v-for="(item, index) in data.about" :imgSrc="item.imgSrc" :titleText="item.titleText"
          :text="item.text" :key="`about${index}`" />
      </div>
    </div>
    <div :class="$style.homeAdvice">
      <h2>{{ data.homeAdviceTitle[lang] }}</h2>
      <div :class="$style.homeAdvice_cont">
        <HomeAdvice v-for="(item, index) in data.advice" :titleText="item.titleText" :text="item.text"
          :key="`advice${index}`" />
      </div>
      <router-link :to="linkTo('/advice')" :class="$style.homeAdvice_link">{{ linkTest[lang] }}</router-link>
    </div>
    <div :class="$style.homeService">
      <h2>{{ data.homeServiceTitle[lang] }}</h2>
      <p>{{ data.homeServiceSubTitle[lang] }}</p>
      <div :class="$style.homeService_cont">
        <HomeService v-for="(item, index) in data.services" :imgSrc="item.imgSrc" :titleText="item.titleText"
          :text="item.text" :key="`services${index}`" />
      </div>
      <router-link :to="linkTo('/service')" :class="$style.homeService_link">{{ linkTest[lang] }}</router-link>
    </div>
  </div>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue';
import HomeAbout from "@/components/HomeAbout.vue";
import HomeAdvice from "@/components/HomeAdvice.vue";
import HomeService from "@/components/HomeService.vue";
import axios from 'axios';

export default {
  components: {
    ContactButton,
    HomeAbout,
    HomeAdvice,
    HomeService
  },
  data() {
    return {
      linkTest: {
        "arm": "Տեսնել Ավելին",
        "eng": "See More",
        "rus": "Узнать больше"
      },
      data: {
        greenLineText1: {},
        greenLineText2: {},
        homeAboutTitle: {},
        homeAdviceTitle: {},
        homeServiceTitle: {},
        homeServiceSubTitle: {},
        about: [],
        advice: [],
        services: []
      },
      imageDataURL: "",
      mobileImageDataURL: "",
      isMobileImageVisible: false
    }

  },
  computed: {
    lang() {
      // Access lang meta field from $route
      return this.$route.meta.lang || 'arm';
    }
  },
  mounted() {
    this.fetchData();
    this.checkDeviceOrientation(); // Check orientation on mount
    window.addEventListener('resize', this.checkDeviceOrientation);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkDeviceOrientation); // Clean up event listener
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/homeData`);

        this.data = response.data.pageData;
        this.imageDataURL = response.data.imageDataURL
        this.mobileImageDataURL = response.data.mobileImageDataURL
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    linkTo(path) {
      const currPath = this.$route.fullPath;

      if (currPath.includes(this.lang)) {
        return `/${this.lang}${path}`;
      } else {
        return `${path}`
      }
    },
    checkDeviceOrientation() {
      // Check if device height is greater than width
      this.isMobileImageVisible = window.innerHeight > window.innerWidth;
    }
  }
}
</script>

<style module>
.cont {
  width: 100%;
  margin-top: 10vh;
}

.cont>header {
  width: 100%;
  height: 90vh;
  /* background-image: url("@/assets/images/headerImg.png"); */
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-bottom: 10vh;
}

.header_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  gap: 5vh;
  color: var(--green);
}

.header_text>h1 {
  text-align: center;
  font-size: 2.4vw;
}

.greenLine {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4vh;
  padding: 7vh 10%;
  background-color: var(--green);
  color: #fff;
}

.greenLine h3 {
  line-height: 46px;
  font-size: 24px;
}

.greenLine p {
  line-height: 31px;
  font-size: 16px;
}

.homeAbout,
.homeAdvice,
.homeService {
  display: flex;
  flex-direction: column;
  padding: 7vh 10%;
  gap: 5vh;
}

.homeAdvice {
  background-color: var(--yellow);
}

.homeAbout h2,
.homeAdvice h2,
.homeService h2 {
  font-size: 38px;
}

.homeAdvice h2 {
  color: #fff;
}

.homeAbout_cont {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5vh;
}

.homeAdvice_cont {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4vh;
}

.homeService_cont {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5vh;
}

.homeAdvice_link,
.homeService_link {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 8px;
  margin: 0 auto;
}

.homeService_link:hover,
.homeAdvice_link:hover {
  background-color: rgb(0, 0, 0, 0.1);
}

.homeAdvice_link {
  color: #fff;
  border: 1px solid #fff;
}

.homeService_link {
  color: var(--green);
  border: 1px solid var(--green);
}


@media screen and (max-width:1200px) {
  .homeService_cont {
    grid-template-columns: 1fr 1fr;
  }

  .header_text {
    align-self: center;
  }

  .header_text>h1 {
    font-size: 4vh;

  }


  .greenLine {
    padding: 7vh 5%;
  }

  .homeAbout,
  .homeAdvice,
  .homeService {
    padding: 7vh 5%;
  }

  .homeAbout_cont {
    gap: 2vh;
  }
}



@media screen and (max-width:700px) {
  .header_text {
    align-self: center;
  }

  .header_text>h1 {
    font-size: 4vh;
  }

  .header_text>div {
    display: none;
  }

  .greenLine {
    padding: 7vh 5%;

  }

  .greenLine h3 {
    line-height: 5vh;
    font-size: 3vh;
  }

  .greenLine p {
    line-height: 4vh;
    font-size: 2vh;
  }

  .homeAbout,
  .homeAdvice,
  .homeService {
    padding: 7vh 5%;
  }

  .homeAbout h2,
  .homeAdvice h2,
  .homeService h2 {
    font-size: 3vh;
  }

  .homeAbout_cont {
    gap: 2vh;
  }

  .homeService p {
    font-size: 2.3vh;
  }

  .homeAdvice_link,
  .homeService_link {
    width: fit-content;
    padding: 5px 16px;
    border-radius: 8px;
    margin: 0 auto;
    font-size: 2vh;
  }
}

@media screen and (max-width:600px) {
  .homeAbout_cont {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
}

@media screen and (max-width:500px) {
  .header_text>h1 {
    font-size: 6vh;

  }

  .header_text>div {
    display: none;
  }

  .header_text>h1 {
    font-size: 4vh;
  }

  .header_text>div {
    display: none;
  }

  .greenLine {
    padding: 7vh 4%;

  }

  .greenLine h3 {
    line-height: 5vh;
    font-size: 2.5vh;
  }

  .greenLine p {
    line-height: 4vh;
    font-size: 2vh;
  }

  .homeAbout_cont {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5vh;
  }

  .homeAdvice_cont {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 4vh;
  }

  .homeService_cont {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5vh;
  }
}
</style>
