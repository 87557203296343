<template>
    <div :class="$style.cont">
        <h4>{{ titleText[lang] }}</h4>
        <div>
            <p>{{ text[lang] }}</p>
            <router-link :to="linkTo('/advice')"> {{ linkText[lang] }} ></router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titleText: Object,
        text: Object
    },
    data() {
        return {
            linkText: {
                arm: "Իմանալ Ավելին",
                eng: "Know more",
                rus: "Узнать больше"
            }
        }
    },
    computed: {
        lang() {
            // Access lang meta field from $route
            return this.$route.meta.lang || 'arm';
        }
    },
    methods: {
        linkTo(path) {
            const currPath = this.$route.fullPath;

            if (currPath.includes(this.lang)) {
                return `/${this.lang}${path}`;
            } else {
                return `${path}`
            }
        }
    }
}
</script>

<style module>
.cont {
    /* width: 48.5%; */
    /* flex-grow: 1; */
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 24px;
    gap: 3vh;
    background-color: #fff;
}

.title {
    display: flex;
    gap: 20px;
}

.cont>div {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}


.cont p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.cont a {
    color: var(--green);
}

@media screen and (max-width:700px) {
    .cont {
        padding: 15px;
    }

    .cont h4 {
        font-size: 2vh;
    }

    .cont p {
        font-size: 2vh;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .cont a {
        font-size: 2vh;
        color: var(--green);
    }

}
</style>