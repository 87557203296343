<template>
    <div :class="$style.cont">
        <section :class="$style.errorContainer">
            <span>4</span>
            <span><span :class="$style.screenReaderText">0</span></span>
            <span>4</span>
        </section>
        <button :class="$style.link" @click="goHome">
            Visit the original article
        </button>
    </div>
</template>

<script>
export default {
    methods: {
        goHome() {
            this.$router.replace("/")
        }
    }
}
</script>
<style module>
.cont {
    height: 100vh;
    background-color: #416475;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.link {
    text-transform: uppercase;
    font-size: 22px;
    background-color: #92a4ad;
    padding: 10px 15px;
    border-radius: 0;
    color: #416475;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 20px;
    letter-spacing: 1px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 1s;
}

.link:hover {
    transform: scale(1.1);
}

.errorContainer {
    text-align: center;
    font-size: 180px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 800;
}

.errorContainer>span {
    display: inline-block;
    line-height: 0.7;
    position: relative;
    color: #FFB485;
}

.errorContainer>span {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.errorContainer>span:nth-of-type(1) {
    color: #D1F2A5;
    animation: colordancing 4s infinite;
}

.errorContainer>span:nth-of-type(3) {
    color: #F56991;
    animation: colordancing2 4s infinite;
}

.errorContainer>span:nth-of-type(2) {
    width: 120px;
    height: 120px;
    border-radius: 999px;
}

.errorContainer>span:nth-of-type(2):before,
.errorContainer>span:nth-of-type(2):after {
    border-radius: 0%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 999px;
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
        inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4),
        inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    animation: shadowsdancing 4s infinite;
}

.errorContainer>span:nth-of-type(2):before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

.screenReaderText {
    position: absolute;
    top: -9999em;
    left: -9999em;
}

@keyframes shadowsdancing {
    0% {
        box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
            inset 0 30px 0 rgba(239, 250, 180, 0.4),
            inset -30px 0 0 rgba(255, 196, 140, 0.4),
            inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    }

    25% {
        box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
            inset 0 30px 0 rgba(209, 242, 165, 0.4),
            inset -30px 0 0 rgba(239, 250, 180, 0.4),
            inset 0 -30px 0 rgba(255, 196, 140, 0.4);
    }

    50% {
        box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
            inset 0 30px 0 rgba(245, 105, 145, 0.4),
            inset -30px 0 0 rgba(209, 242, 165, 0.4),
            inset 0 -30px 0 rgba(239, 250, 180, 0.4);
    }

    75% {
        box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
            inset 0 30px 0 rgba(255, 196, 140, 0.4),
            inset -30px 0 0 rgba(245, 105, 145, 0.4),
            inset 0 -30px 0 rgba(209, 242, 165, 0.4);
    }

    100% {
        box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
            inset 0 30px 0 rgba(239, 250, 180, 0.4),
            inset -30px 0 0 rgba(255, 196, 140, 0.4),
            inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    }
}

@keyframes colordancing {
    0% {
        color: #D1F2A5;
    }

    25% {
        color: #F56991;
    }

    50% {
        color: #FFC48C;
    }

    75% {
        color: #EFFAB4;
    }

    100% {
        color: #D1F2A5;
    }
}

@keyframes colordancing2 {
    0% {
        color: #FFC48C;
    }

    25% {
        color: #EFFAB4;
    }

    50% {
        color: #D1F2A5;
    }

    75% {
        color: #F56991;
    }

    100% {
        color: #FFC48C;
    }
}

@media screen and (max-width: 400px) {
    .errorContainer {
        font-size: 130px;
    }
    .link {
        font-size: 16px;
    }
}
</style>