<template>
    <nav v-if="!isSmallScreen">
        <div :class="[$style.logo, { [$style.smallLogo]: isSmallScreen }]">
            <router-link :to="linkTo()">
                <img src="@/assets/images/logo.png" alt="">
            </router-link>
        </div>
        <NavBarLinks :size="'standart'" />
        <div :class="$style.rightSide">
            <NavBarLanguage :langs="allowedLanguages" />
            <ContactButton />
        </div>

    </nav>
    <nav v-else>
        <div :class="[$style.logo, { [$style.smallLogo]: isSmallScreen }]">
            <router-link :to="linkTo()">
                <img src="@/assets/images/logo.png" alt="">
            </router-link>
        </div>
        <div :class="[$style.burger, { [$style.activeBurger]: isMenuOpen }]" @click="toggleMenu">
            <div :class="$style.line"></div>
            <div :class="$style.line"></div>
            <div :class="$style.line"></div>
        </div>
        <div v-if="isMenuOpen" :class="$style.burgerMenu">
            <NavBarLinks :size="'small'" @linkClicked="toggleMenu" />
            <div :class="$style.burgerMenuBottom">
                <NavBarLanguage :langs="allowedLanguages" :size="'small'" />
                <ContactButton />
            </div>
        </div>
    </nav>
</template>

<script>
import { mapState } from 'vuex';
import ContactButton from '@/components/ContactButton';
import NavBarLinks from '@/components/NavBarLinks';
import NavBarLanguage from "@/components/NavBarLanguage"
export default {
    components: {
        ContactButton,
        NavBarLinks,
        NavBarLanguage
    },
    data() {
        return {
            isSmallScreen: false,
            isMenuOpen: false,
            
        };
    },
    computed: {
        ...mapState(['allowedLanguages']),
        lang() {
            // Access lang meta field from $route
            return this.$route.meta.lang || 'arm';
        }
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    unmounted() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    methods: {
        linkTo() {
            const currPath = this.$route.fullPath;

            if (currPath.includes(this.lang)) {
                return `/${this.lang}/`;
            } else {
                return `/`
            }
        },
        checkScreenSize() {
            this.isSmallScreen = window.innerWidth < 1000;
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        }
    }
}
</script>

<style module>
nav {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0 10%;
    position: fixed;
    top: 0;
    left: 0;
}

.logo {
    height: 80%;
}

.logo>a>img {
    height: 100%;
    object-fit: contain;
}

.rightSide {
    display: flex;
    gap: 10%;
}

.burger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 20px;
    position: relative;
    z-index: 1;
}

.burgerMenu {
    width: 250px;
    height: 90vh;
    position: absolute;
    top: 10vh;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 5vh;
    background-color: #fff;
    padding: 4vh 20px;
    animation: show .5s ease 1 forwards;
}

.burgerMenuBottom {
    display: flex;
    gap: 8%;
    border-top: 1px solid var(--green);
    padding: 4vh 10%;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: all 0.5s ease;
}

.activeBurger .line:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
}

.activeBurger .line:nth-child(2) {
    opacity: 0;
}

.activeBurger .line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
}

.smallLogo {
    height: 60%;
}

@keyframes show {
    0% {
        transform: translateX(100%)
    }

    100% {
        transform: translateX(0)
    }
}


@media screen and (max-width: 1200px) {
    nav {
        padding: 0 5%;
    }
}

@media screen and (max-width: 500px) {
    .burgerMenu {
        width: 100%;
    }

    .burgerMenuBottom {
        padding: 3vh 3%;
    }
}
</style>
