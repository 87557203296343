<template>
    <div :class="[$style.cont, { [$style.smallScreen]: size === 'small' }]">
        <router-link :to="linkTo(item.path)" v-for="item in links" :key="item.path" @click="handleClick">{{
            item.text[lang] }}</router-link>
    </div>
</template>

<script>
export default {
    props: {
        size: String
    },
    data() {
        return {
            links: [
                {
                    path: "/about",
                    text: {
                        arm: "Մեր մասին",
                        eng: "About",
                        rus: "О нас"
                    }
                },
                {
                    path: "/advice",
                    text: {
                        arm: "Խորհրդատվություն",
                        eng: "Consultation",
                        rus: "Консультация"
                    }
                },
                {
                    path: "/service",
                    text: {
                        arm: "Ծառայություններ",
                        eng: "Services",
                        rus: "Услуги"
                    }
                }
            ]
        }
    },
    computed: {
        lang() {
            // Access lang meta field from $route
            return this.$route.meta.lang || 'arm';
        }
    },
    methods: {
        linkTo(path) {
            const currPath = this.$route.fullPath;

            if (currPath.includes(this.lang)) {
                return `/${this.lang}${path}`;
            } else {
                return `${path}`
            }
        },
        handleClick() {
            this.$emit('linkClicked');
        }
    }
}
</script>

<style module>
.cont {
    width: fit-content;
    display: flex;
    gap: 50px;
}

.cont a {
    font-size: 16px;
    color: #000;
}

.cont a:visited {
    color: #000;
}

.smallScreen {
    width: 100%;
    flex-direction: column;
    padding: 0 10%;
}


@media screen and (max-width: 700px) {
    .smallScreen {
        padding: 4vh 3%;
    }
}
</style>

<style>
a.router-link-active {
    color: var(--green) !important;
    text-decoration: underline;
}
</style>