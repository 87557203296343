<template>
  <div :class="$style.cont">
    <header>
      <h1>{{ data.headerTitle[lang] }}</h1>
      <img :src="imageDataURL" alt="">
      <div>
        <p>{{ data.headerText1[lang] }}</p>
        <p>{{ data.headerText2[lang] }}</p>
      </div>
    </header>
    <main>
      <AboutSection v-for="(item, index) in data.sections" :title="item.title" :lists="item.lists" :key="`aaboutPage${index}`" />

    </main>
  </div>
</template>
<script>
import axios from 'axios';
import AboutSection from "@/components/AboutSection"

export default {
  components:{
    AboutSection
  },
  data() {
    return {
      imageDataURL: "",
      data: {
        headerTitle: {},
        headerText1: {},
        headerText2: {},
        sections: []
      }
    }
  },
  computed: {
    lang() {
      // Access lang meta field from $route
      return this.$route.meta.lang || 'arm';
    }
  },
  mounted() {
    this.fetchData();

  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/aboutData`);

        this.data = response.data.pageData;
        this.imageDataURL = response.data.imageDataURL;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  }
}
</script>

<style module>
.cont {
  width: 100%;
  margin-top: 10vh;
}


.cont>header {
  width: 100%;
  /* height: 115vh; */
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  background: linear-gradient(90deg, var(--yellow) 0% 40%, #fff 40%);
  color: #fff;
}

.cont>header>img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.cont>header>div {
  display: flex;
  justify-content: space-between;
}

.cont>header>div>p {
  width: 35%;
  font-size: 1.3vw;
  line-height: 31px;
}

.cont>header>div>p:last-child {
  width: 55%;
  font-size: 1.2vw;
  color: #000;
}

.cont>main {
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

@media screen and (max-width:1200px){
  .cont>header {
    height: auto;
    padding: 4vh 5% 3vh;
  }

  .cont>main {
    padding: 3vh 5% 4vh;

  }
}


@media screen and (max-width:900px) {
  .cont>header {
    height: auto;
    padding: 4vh 5% 3vh;
    background: linear-gradient(var(--yellow) 0% 40%, #fff 40%);
    color: #000;
  }

  .cont>header>div {
    flex-direction: column;
    gap: 2vh;
    font-size: 17px;
  }

  .cont>header>div>p {
    width: 100% !important;
    font-size: 21px;
    line-height: 31px;
  }

  .cont>header>div>p:last-child {
    font-size: 16px;
  }

  .cont>main {
    padding: 3vh 5% 4vh;

  }

}

@media screen and (max-width:600px) {
  .cont>header {
    background: linear-gradient(var(--yellow) 0% 30%, #fff 30%);
  }
}

@media screen and (max-width:500px) {
  .cont>header {
    background: linear-gradient(var(--yellow) 0% 25%, #fff 25%);
  }
  .cont h1{
    font-size: 28px;
  }
}
</style>
