<template>
    <div :class="$style.cont">
        <button :class="$style.remove" @click="$emit('removeEl', index)">Remove</button>
        <input v-model="ctitleText[lang]" />
        <ul>
            <button :class="$style.addBut" @click="() => this.isAdd = true">Add List Element</button>
            <div v-if="isAdd" :class="$style.addCont">
                <label>
                    Text
                    <input type="text" v-model="addText">
                </label>
                <button :class="$style.subBut" @click="addList()">OK</button>
            </div>
            <li v-for="(item, index) in clists" :key="`servicePageLi${index}`">
                <textarea type="text" v-model="item[lang]"></textarea>
                <button :class="$style.removeList" @click="removeListEl(index)">-</button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        title: Object,
        lists: Array,
        lang: String,
        index: Number
    },
    computed: {
        ctitleText() {
            return this.title || {}; 
        },
        clists() {
            return this.lists || []; 
        }
    },
    data() {
        return {
            isAdd: false,
            addText: "",
        }
    },
    methods: {
        addList() {
            this.clists.push(
                {
                    [this.lang]: this.addText
                });
            this.addText = "",
            this.isAdd = false
        },
        removeListEl(index) {
            this.clists.splice(index, 1)
        },
    },
};
</script>

<style module>
.cont {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    position: relative;
    border: 1px solid rgb(233, 233, 233);
}

.cont input {
    font-size: 30px;
}

.cont textarea {
    width: 100%;
    min-height: 80px;
    height: auto;
    padding: 5px;
    resize: vertical;
}

.cont ul {
    font-size: 21px;
    line-height: 31px;
    padding-left: 5px;
}

.cont li {
    margin-left: 25px;
    padding: 4px 0;
    font-size: 16px;
    position: relative;

}

.remove {
    position: absolute;
    top: -13px;
    right: 10px;
    width: fit-content;
    border: 1px solid red;
    background-color: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 7px;
    cursor: pointer;
}

.removeList {
    position: absolute;
    top: -10px;
    right: 5px;
    width: fit-content;
    border: 1px solid red;
    background-color: red;
    color: #fff;
    padding: 2px 10px;
    border-radius: 7px;
    cursor: pointer;
}

.addBut {
    width: fit-content;
    background-color: green;
    color: #fff;
    border: 1px solid green;
    outline: none;
    padding: 4px 10px;
    margin: 5px 0;
    border-radius: 7px;
    font-size: 14px;
    cursor: pointer;
}

.addButSection {
    position: absolute;
    top: -13px;
    right: 90px;
    width: fit-content;
    background-color: green;
    color: #fff;
    border: 1px solid green;
    outline: none;
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 14px;
    cursor: pointer;
}

.addCont {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    background-color: #fff;
    padding: 10px;
    margin: 10px 0;
    border-radius: 7px;
    font-size: 14px;
    border: 1px solid #000;
}

.addCont>label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.subBut {
    width: fit-content;
    color: green;
    border: 1px solid green;
    background-color: transparent;
    outline: none;
    padding: 4px 15px;
    border-radius: 7px;
    font-size: 16px;
    cursor: pointer;
}
</style>
