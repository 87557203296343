<template>
    <div :class="$style.cont">
        <div :class="$style.controller">
            <button @click="changeLange('arm')">ARM</button>
            <button @click="changeLange('eng')">ENG</button>
            <button @click="changeLange('rus')">RUS</button>
            <button @click="saveData">Save</button>
        </div>
        <div :class="$style.btnImageCont">
            <button :class="$style.btnImage" @click="onPickFile">Upload Image</button>
            <button :class="$style.btnImage" @click="removeNewImage">Remove Image</button>
            <button :class="$style.btnImage" @click="saveNewImage">Save Image</button>
            <button :class="$style.btnImage" @click="swichImage">
                {{ isMobileImageVisible ? "Desktop Image" : "Mobile Image" }}
            </button>
            <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked" />
        </div>
        <header :style="{
            'background-image': isMobileImageVisible ? 'url(' + this.mobileImageDataURL + ')' : 'url(' + this.imageDataURL + ')',
            'background-size': isMobileImageVisible ? '450px 600px' : 'cover'
        }">
            <div :class="$style.header_text">
                <textarea v-model="data.headerText"></textarea>
            </div>
        </header>

        <div :class="$style.greenLine">
            <textarea v-model="data.greenLineText1[this.currLange]"></textarea>
            <textarea v-model="data.greenLineText2[this.currLange]"></textarea>
        </div>
        <div :class="$style.homeAbout">
            <h2>{{ data.homeAboutTitle[currLange] }}</h2>
            <div :class="$style.homeAbout_cont">
                <AdminHomeAbout v-for="(item, index) in data.about" :imgSrc="item.imgSrc" :titleText="item.titleText"
                    :text="item.text" :lang="currLange" :key="`about${index}`" />
            </div>
        </div>
        <div :class="$style.homeAdvice">
            <h2>{{ data.homeAdviceTitle[currLange] }}</h2>
            <button :class="$style.addBut" @click="() => this.isAddAdvice = true">Add Element</button>
            <div v-if="isAddAdvice" :class="$style.addCont">
                <label>
                    Title
                    <input type="text" v-model="addAdviceTitle">
                </label>
                <label>
                    Text
                    <input type="text" v-model="addAdviceText">
                </label>
                <button :class="$style.subBut" @click="addAdvice">OK</button>
            </div>
            <div :class="$style.homeAdvice_cont">
                <AdminHomeAdvice v-for="(item, index) in data.advice" @removeEl="removeAdviceEL" :index="index"
                    :lang="currLange" :titleText="item.titleText" :text="item.text" :key="`advice${index}`" />
            </div>
        </div>
        <div :class="$style.homeService">
            <h2>{{ data.homeServiceTitle[currLange] }}</h2>
            <textarea v-model="data.homeServiceSubTitle[this.currLange]"></textarea>
            <button :class="$style.addBut" @click="() => this.isAddService = true">Add Element</button>
            <div v-if="isAddService" :class="$style.addCont">
                <label>
                    Title
                    <input type="text" v-model="addServiceTitle">
                </label>
                <label>
                    Text
                    <input type="text" v-model="addServiceText">
                </label>
                <button :class="$style.subBut" @click="addService">OK</button>
            </div>
            <div :class="$style.homeService_cont">
                <AdminHomeService v-for="(item, index) in data.services" @removeEl="removeServiceEL" :index="index"
                    :lang="currLange" :titleText="item.titleText" :text="item.text" :key="`services${index}`" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import AdminHomeAdvice from "@/components/Admin/AdminHomeAdvice.vue";
import AdminHomeAbout from "@/components/Admin/AdminHomeAbout.vue";
import AdminHomeService from "@/components/Admin/AdminHomeService.vue";
import { mapActions } from 'vuex';

export default {
    components: {
        AdminHomeAdvice,
        AdminHomeAbout,
        AdminHomeService
    },
    data() {
        return {
            imageDataURL: "",
            imageDataURLOld: "",
            mobileImageDataURL: '',
            mobileImageDataURLOld: '',
            isMobileImageVisible: false,
            currLange: "arm",
            isAddAdvice: false,
            isAddService: false,
            addAdviceTitle: "",
            addAdviceText: "",
            addServiceTitle: "",
            addServiceText: "",
            data: {
                greenLineText1: {},
                greenLineText2: {},
                homeAboutTitle: {},
                homeAdviceTitle: {},
                homeServiceTitle: {},
                homeServiceSubTitle: {},
                about: [],
                advice: [],
                services: []
            }
        }

    },
    mounted() {
        this.fetchData();
    },
    methods: {
        ...mapActions(["triggerAlert"]),

        async fetchData() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/homeData`);

                this.data = response.data.pageData;
                this.imageDataURL = response.data.imageDataURL;
                this.mobileImageDataURL = response.data.mobileImageDataURL;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async saveData() {
            try {
                let token = sessionStorage.getItem("jknkjnk");
                await axios.post(`${process.env.VUE_APP_API_URL}/api/saveHomeData`, this.data, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                this.triggerAlert({
                    type: "success",
                    message: "Data saved successfully!",
                });
            } catch (error) {
                this.triggerAlert({
                    type: "warning",
                    message: "Something is wrong. Please try agian!",
                });
            }
        },
        async saveNewImage() {
            try {
                // Convert data URL to Blob
                const blob = await fetch(this.imageDataURL).then((res) => res.blob());

                // Create a new File object from the Blob
                const file = new File([blob], 'image.png', { type: 'image/png' });
                const formData = new FormData();
                formData.append('image', file);

                const uploadImagePath = this.isMobileImageVisible ? "homeMobile" : "home";

                let token = sessionStorage.getItem("jknkjnk");
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/uploadImage/${uploadImagePath}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                });

                // Update imageDataURL with the new image data URL received from the server
                this.imageDataURL = response.data.imageDataURL;

                this.triggerAlert({
                    type: "success",
                    message: "Image saved successfully!",
                });
            } catch (error) {
                this.triggerAlert({
                    type: "warning",
                    message: "Something is wrong. Please try agian!",
                });
            }
        },

        swichImage() {
            this.isMobileImageVisible = !this.isMobileImageVisible
        },

        onPickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(event) {
            const files = event.target.files;
            if (this.isMobileImageVisible) {

                this.mobileImageDataURLOld = this.mobileImageDataURL;
                const fileReader = new FileReader()
                fileReader.addEventListener('load', () => {
                    this.mobileImageDataURL = fileReader.result
                })
                fileReader.readAsDataURL(files[0])
                this.mobileImageDataURL = files[0]
            } else {

                this.imageDataURLOld = this.imageDataURL;

                const fileReader = new FileReader()
                fileReader.addEventListener('load', () => {
                    this.imageDataURL = fileReader.result
                })
                fileReader.readAsDataURL(files[0])
                this.imageDataURL = files[0]

            }

        },
        removeNewImage() {
            if (this.isMobileImageVisible) {
                if (this.mobileImageDataURLOld) {
                    this.mobileImageDataURL = this.mobileImageDataURLOld
                }
            } else {
                if (this.imageDataURLOld) {
                    this.imageDataURL = this.imageDataURLOld;
                }
            }
            // Clear the file input value to reset it
            const fileInput = this.$refs.fileInput;
            if (fileInput) {
                fileInput.value = ''; // Clear the file input value
            }
        },

        addAdvice() {
            this.data.advice.push({
                titleText: { [this.currLange]: this.addAdviceTitle },
                text: { [this.currLange]: this.addAdviceText },
            })
            this.addAdviceTitle = "",
                this.addAdviceText = "",
                this.isAddAdvice = false
        },
        addService() {
            this.data.services.push({
                titleText: { [this.currLange]: this.addServiceTitle },
                text: { [this.currLange]: this.addServiceText },
            })
            this.addServiceTitle = "",
                this.addServiceText = "",
                this.isAddService = false
        },
        removeAdviceEL(index) {
            this.data.advice = this.data.advice.filter((el, ind) => {
                return ind !== index
            })
        },
        removeServiceEL(index) {
            console.log("stex ");
            console.log(index);
            this.data.services = this.data.services.filter((el, ind) => {
                return ind !== index
            })
            console.log(this.data.services);
        },
        changeLange(lang) {
            this.currLange = lang
        }

    }
}
</script>

<style module>
.cont {
    width: 100%;
    margin-top: 4vh;
}

.controller {
    position: fixed;
    right: 30px;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.controller>button {
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    background-color: rgb(66, 66, 66);
    color: #fff;
    transition: .3s
}

.controller>button:hover {
    background-color: #fff;
    color: #000;
}

.addBut {
    width: fit-content;
    background-color: green;
    color: #fff;
    border: 1px solid green;
    outline: none;
    padding: 6px 20px;
    border-radius: 7px;
    font-size: 18px;
    cursor: pointer;
}

.addCont {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #000;
}

.addCont>label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.subBut {
    width: fit-content;
    color: green;
    border: 1px solid green;
    background-color: transparent;
    outline: none;
    padding: 4px 15px;
    border-radius: 7px;
    font-size: 16px;
    cursor: pointer;
}


.cont textarea {
    width: 100%;
    min-height: 80px;
    height: fit-content;
    padding: 5px;
    resize: vertical;
}

.cont>header {
    width: 100%;
    height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10vh;
    margin-top: 20px;
}

.btnImageCont {
    display: flex;
    gap: 30px;
}

.btnImage:hover {
    transform: scale(1.1);
}

.btnImage {
    padding: 5px 12px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    color: #fff;
}

.btnImage:nth-child(1) {
    background-color: orange;
}

.btnImage:nth-child(2) {
    background-color: red;
}

.btnImage:nth-child(3) {
    background-color: green;
}

.btnImage:nth-child(4) {
    background-color: rgb(0, 41, 128);
}

.header_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
    color: var(--green);
}


.greenLine {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4vh;
    padding: 7vh 10%;
    background-color: var(--green);
    color: #fff;
}


.homeAbout,
.homeAdvice,
.homeService {
    display: flex;
    flex-direction: column;
    padding: 7vh 10%;
    gap: 5vh;
}

.homeAdvice {
    background-color: var(--yellow);
}

.homeAbout h2,
.homeAdvice h2,
.homeService h2 {
    font-size: 38px;
}

.homeAdvice h2 {
    color: #fff;
}

.homeAbout_cont {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5vh;
}

.homeAdvice_cont {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4vh;
}

.homeService_cont {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5vh;
}

.homeAdvice_link,
.homeService_link {
    width: fit-content;
    padding: 8px 16px;
    border-radius: 8px;
    margin: 0 auto;
}

.homeService_link:hover,
.homeAdvice_link:hover {
    background-color: rgb(0, 0, 0, 0.1);
}

.homeAdvice_link {
    color: #fff;
    border: 1px solid #fff;
}

.homeService_link {
    color: var(--green);
    border: 1px solid var(--green);
}
</style>
