<template>
    <div :class="$style.cont">
        <div :class="$style.title">
            <img :src="svgIcon" :alt="imgSrc">
            <h4>{{ titleText[lang] }}</h4>
        </div>
        <div>
            <p>{{ text[lang] }}</p>
            <router-link :to="linkTo('/about')">{{ linkText[lang] }} ></router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imgSrc: String,
        titleText: Object,
        text: Object
    },
    data() {
        return {
            svgIcon: require(`@/assets/images/${this.imgSrc}.svg`), // Adjust the path as needed
            linkText: {
                arm: "Իմանալ Ավելին",
                eng: "Know more",
                rus: "Узнать больше"
            }
        }
    },
    computed: {
        lang() {
            // Access lang meta field from $route
            return this.$route.meta.lang || 'arm';
        }
    },
    methods: {
        linkTo(path) {
            const currPath = this.$route.fullPath;

            if (currPath.includes(this.lang)) {
                return `/${this.lang}${path}`;
            } else {
                return `${path}`
            }
        }
    }
}
</script>

<style module>
.cont {
    /* width: 30%; */
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--green);
    border-radius: 24px;
    gap: 3vh;
}

.title {
    display: flex;
    align-items: center;
    gap: 10px;

}

.cont>div:last-child {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.cont p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.cont a {
    color: var(--green);
}

@media screen and (max-width:1200px) {
    .title {
        flex-direction: column;
        font-size: 1.4vw;
    }

    .title>img {
        width: 30%;
    }
}

@media screen and (max-width:700px) {
    .cont {
        padding: 15px;
    }

    .title {
        flex-direction: column;
        font-size: 1.8vh;
    }

    .cont p {
        font-size: 2vh;
    }

    .cont a {
        font-size: 2vh;
    }

}

@media screen and (max-width:500px) {
    .title {
        font-size: 3.5vh;
    }

    .title>img {
        width: 20%;
    }

}

@media screen and (max-width:400px) {
    .title {
        font-size: 2.5vh;
    }

    .title>img {
        width: 20%;
    }

}
</style>