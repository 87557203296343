import axios from "axios";

export const getAllowedLanguages = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/settings/allowedLanguages`);
    return response.data.allowedLanguages;
  } catch (error) {
    console.error("Error fetching allowed languages:", error);
    throw error;
  }
};

export const updateAllowedLanguages = async (token, allowedLanguages) => {
  try {
    const response = await axios.post(
      "/api/settings/changeLanguages",
      { allowedLanguages },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.message;
  } catch (error) {
    console.error("Error updating allowed languages:", error);
    throw error;
  }
};
