import { createApp } from 'vue'
import App from './App.vue'
// import router from './router'
import store from './store'


import initializeRouter from './router';

const bootstrapApp = async () => {
  const router = await initializeRouter();
  const app = createApp(App);
  app.use(router);
  app.use(store);
  app.mount('#app');
};

bootstrapApp();