<template>
    <div :class="$style.cont">
        <div :class="$style.background">
            <div :class="$style.shape"></div>
            <div :class="$style.shape"></div>
        </div>
        <form @submit="handleSubmit">
            <h3>Login Here</h3>

            <label for="username">Username</label>
            <input type="text" id="username" v-model="username">

            <label for="password">Password</label>
            <input type="password" id="password" v-model="password">

            <button>Log In</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            username: "",
            password: "",
        }
    },
    methods: {

        async handleSubmit(ev) {
            ev.preventDefault()
            let reqData = {
                username: this.username,
                password: this.password
            };
            let response = await axios.post(`${process.env.VUE_APP_API_URL}/api/authentication/login`, reqData);
            if(response.status === 200){
                sessionStorage.setItem("jknkjnk",response.data.token);
                this.$router.replace("/admin/home")
            }
        }
    }

}
</script>

<style module>
.cont {
    width: 100%;
    height: 100vh;
    background-color: #080710;
    overflow: hidden;
}

.background {
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.background .shape {
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}

.shape:first-child {
    background: linear-gradient(#1845ad,
            #23a2f6);
    left: -80px;
    top: -80px;
}

.shape:last-child {
    background: linear-gradient(to right,
            #ff512f,
            #f09819);
    right: -70px;
    bottom: 40px;
}

.cont form {
    height: 520px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
}

.cont form * {
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.cont form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

.cont label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.cont input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

.cont button {
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}
</style>
