<template>
    <div :class="$style.cont">
        <a href="tel:+01 234 567 89">Contact</a> 
    </div>
</template>

<script>
export default {

}
</script>

<style module>
.cont {
    width: fit-content;
    padding: 6px 1.2vw;
    border: 1px solid var(--green);
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
}

.cont a{
    color: var(--green);
}

.cont:hover {
    background-color: rgb(0, 0, 0, 0.1);
}


@media screen and (max-width:1150px) and (min-height:1000px) {
    .cont {
        padding: 10px 1.2vw;
        font-size: 1.5vh;

    }
}

</style>
