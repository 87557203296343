<template>
    <div :class="$style.cont">
        <button :class="$style.remove" @click="$emit('removeEl', index)">Remove</button>
        <textarea v-model="ctitleText[lang]"></textarea>
        <div>
            <textarea v-model="ctext[lang]"></textarea>
            <router-link to="/service">{{ link[lang] }} ></router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titleText: Object,
        text: Object,
        index: Number,
        lang: String
    },
    computed: {
        ctitleText() {
            return this.titleText
        },
        ctext() {
            return this.text
        }
    },
    data() {
        return {
            link: {
                arm: "Իմանալ Ավելին",
                eng: "Know more",
                rus: "Узнать больше"
            }
        }
    }
}
</script>

<style module>
.cont {
    /* width: 30%; */
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--green);
    border-radius: 24px;
    gap: 3vh;
    position: relative;

}

.title {
    display: flex;
    gap: 20px;
    font-size: 16px;
}
 
.cont > div{
    display: flex;
    flex-direction: column;
    gap: 3vh;
}


.cont p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.cont a {
    color: var(--green);
}

.remove {
    position: absolute;
    top: -13px;
    right: 10px;
    width: fit-content;
    border: 1px solid red;
    background-color: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 7px;
    cursor: pointer;
}

</style>