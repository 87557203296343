<template>
  <div :class="$style.cont">
    <div :class="$style.controller">
      <button @click="changeLange('arm')">ARM</button>
      <button @click="changeLange('eng')">ENG</button>
      <button @click="changeLange('rus')">RUS</button>
      <button @click="saveData">Save</button>
    </div>
    <header>
      <h1>{{ data.headerTitle[currLange] }}</h1>
      <div :class="$style.btnImageCont">
        <button :class="$style.btnImage" @click="onPickFile">Upload</button>
        <button :class="$style.btnImage" @click="removeNewImage">Remove</button>
        <button :class="$style.btnImage" @click="saveNewImage">Save</button>
        <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked" />
      </div>
      <img :src="imageDataURI" alt="">
      <div>
        <textarea v-model="data.headerText1[currLange]"></textarea>
        <textarea v-model="data.headerText2[currLange]"></textarea>
      </div>
    </header>
    <main>
      <button :class="$style.addBut" @click="() => this.isAdd = true">Add Element</button>
      <div v-if="isAdd" :class="$style.addCont">
        <label>
          Title
          <input type="text" v-model="addTitle">
        </label>
        <label>
          SubTitle
          <input type="text" v-model="addSubTitle">
        </label>
        <label>
          Text
          <input type="text" v-model="addText">
        </label>
        <button :class="$style.subBut" @click="addSection">OK</button>
      </div>
      <AdminAboutSection v-for="(item, index) in data.sections" :title="item.title" :lists="item.lists"
        :lang="currLange" :index="index" @removeEl="removeEL" :key="`aboutPage${index}`" />
    </main>
  </div>
</template>
<script>
import axios from 'axios';
import AdminAboutSection from "@/components/Admin/AdminAboutSection.vue"
import { mapActions } from 'vuex';

export default {
  components: {
    AdminAboutSection
  },
  data() {
    return {
      imageDataURI: "",
      imageDataURIOld: "",
      currLange: "arm",
      isAdd: false,
      addTitle: "",
      addText: "",
      addSubTitle: "",
      data: {
        headerTitle: {},
        headerText1: {},
        headerText2: {},
        sections: []
      },

    }
  },
  mounted() {
    this.fetchData();

  },
  methods: {
    ...mapActions(["triggerAlert"]),
    async fetchData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/aboutData`);

        this.data = response.data.pageData;
        this.imageDataURI = response.data.imageDataURL
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async saveData() {
      try {
        let token = sessionStorage.getItem("jknkjnk");
        await axios.post(`${process.env.VUE_APP_API_URL}/api/saveAboutData`, this.data, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.triggerAlert({
          type: "success",
          message: "Data saved successfully!",
        });
      } catch (error) {
        this.triggerAlert({
          type: "warning",
          message: "Something is wrong. Please try agian!",
        });
      }
    },
    async saveNewImage() {
      try {
        // Convert data URL to Blob
        const blob = await fetch(this.imageDataURI).then((res) => res.blob());

        // Create a new File object from the Blob
        const file = new File([blob], 'image.png', { type: 'image/png' });
        const formData = new FormData();
        formData.append('image', file);

        let token = sessionStorage.getItem("jknkjnk");
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/uploadImage/about`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });

        console.log(response.data.message);
        // Update imageDataURI with the new image data URL received from the server
        this.imageDataURI = response.data.imageDataURL;
        this.triggerAlert({
          type: "success",
          message: "Image saved successfully!",
        });
      } catch (error) {
        this.triggerAlert({
          type: "warning",
          message: "Something is wrong. Please try agian!",
        });
      }
    },

    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      const files = event.target.files;
      this.imageDataURIOld = this.imageDataURI;

      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageDataURI = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.imageDataURI = files[0]
    },
    removeNewImage() {
      if (this.imageDataURIOld) {
        this.imageDataURI = this.imageDataURIOld;

        // Clear the file input value to reset it
        const fileInput = this.$refs.fileInput;
        if (fileInput) {
          fileInput.value = ''; // Clear the file input value
        }
      }
    },
    changeLange(lang) {
      this.currLange = lang
    },
    addSection() {
      this.sections.push({
        title: { [this.currLange]: this.addTitle },
        lists: [
          {
            subTitle: { [this.currLange]: this.addSubTitle },
            listElems: [{ [this.currLange]: this.addText }]
          }
        ],
      })
      this.addTitle = "";
      this.addText = "";
      this.addSubTitle = "";
      this.isAdd = false
    },
    removeEL(index) {
      this.data.sections = this.data.sections.filter((el, ind) => {
        return ind !== index
      })
    }
  }
}
</script>

<style module>
.cont {
  width: 100%;
  margin-top: 4vh;
}

.controller {
  position: fixed;
  right: 30px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.controller>button {
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
  background-color: rgb(66, 66, 66);
  color: #fff;
  transition: .3s
}

.controller>button:hover {
  background-color: #fff;
  color: #000;
}

.cont textarea {
  width: 100%;
  min-height: 80px;
  height: auto;
  padding: 5px;
  resize: vertical;
}

.cont>header {
  width: 100%;
  height: 115vh;
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  background: linear-gradient(90deg, var(--yellow) 0% 40%, #fff 40%);
  color: #fff;
}

.cont>header>img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.cont>header>div:not(.btnImageCont) {
  display: flex;
  justify-content: space-between;
}

.cont>header>div>textarea {
  width: 35%;
  font-size: 20px;
  line-height: 31px;
}

.cont>header>div>textarea:last-child {
  width: 55%;
  font-size: 18px;
  color: #000;
}

.cont>main {
  padding: 6vh 10%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.addBut {
  width: fit-content;
  background-color: green;
  color: #fff;
  border: 1px solid green;
  outline: none;
  padding: 6px 20px;
  border-radius: 7px;
  font-size: 18px;
  cursor: pointer;
}

.addCont {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  background-color: #fff;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #000;
}

.addCont>label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subBut {
  width: fit-content;
  color: green;
  border: 1px solid green;
  background-color: transparent;
  outline: none;
  padding: 4px 15px;
  border-radius: 7px;
  font-size: 16px;
  cursor: pointer;
}

.btnImageCont {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.btnImage {
  padding: 5px 12px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #fff;
  color: #fff;
}

.btnImage:hover {
  transform: scale(1.1);
}

.btnImage:nth-child(1) {
  background-color: orange;
}

.btnImage:nth-child(2) {
  background-color: red;
}

.btnImage:nth-child(3) {
  background-color: green;
}
</style>