<template>
    <div :class="$style.cont">
        <button :class="$style.remove" @click="$emit('removeEl', index)">Remove</button>
        <textarea v-model="ctitleText[lang]"></textarea>
        <div>
            <textarea v-model="ctext[lang]"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: Object,
        text: Object,
        index: Number,
        lang: String
    },
    computed: {
        ctitleText() {
            return this.title
        },
        ctext() {
            return this.text
        }
    },
}
</script>

<style module>
.cont {
    /* width: 48.5%; */
    /* flex-grow: 1; */
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 24px;
    gap: 3vh;
    background-color: #fff;
    position: relative;
}

.cont textarea {
    resize: vertical;
    height: max-content;
}

.title {
    display: flex;
    gap: 20px;
}

.cont>div {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}




.cont p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.cont a {
    color: var(--green);
}


.remove {
    position: absolute;
    top: -13px;
    right: 10px;
    width: fit-content;
    border: 1px solid red;
    background-color: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 7px;
    cursor: pointer;
}
</style>